import api from "../api"

class GraffitiService {

	static getGraffitis(page) {
		return api({
      url: 'graffitis',
      params: {
        page
      }
		})
  }

  static getAllGraffitis() {
		return api({
			url: 'graffitis-all'
		})
	}

	static storeGraffiti(data) {
		return api({
			method: 'post',
			data: data,
			url: 'graffitis'
		})
	}

	static updateGraffiti(data) {
		return api({
			method: 'post',
			data: data,
			url: 'graffitis'
		})
	}

	static deleteGraffiti(data) {
		return api({
			method: 'delete',
			data: data,
			url: 'graffitis'
		})
	}

}

export default GraffitiService
