<template>
  <div>
    <div class="row">
      <div class="col d-flex justify-content-between">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Codigos QR',
              active: true
            }
          ]"
        ></vs-breadcrumb>
        <div>
          <vs-button icon="add" @click="open = true" type="gradient">Crear nuevo</vs-button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <vs-card>
          <vs-table stripe :data="graffitis">
            <template slot="header">
              <h4>
                Lista de graffitis (con qr)
              </h4>
            </template>
            <template slot="thead">
              <vs-th>
                Nombre
              </vs-th>
              <vs-th>
                Autor
              </vs-th>
              <vs-th>
                Imagen
              </vs-th>
              <vs-th>
                Qr
              </vs-th>
              <vs-th>
                Acciones
              </vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr class="text-left" :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td :data="data[indextr].name">
                  {{data[indextr].name}}
                </vs-td>

                <vs-td :data="data[indextr].author">
                  {{data[indextr].author}}
                </vs-td>

                <vs-td :data="data[indextr].image">
                  {{data[indextr].image}}
                </vs-td>

                <vs-td :data="data[indextr].url_qr">
                  <div id="print" class="print">
                    <vue-qr
                      class="qr"
                      :size="120"
                      :text="data[indextr].url_qr"
                      :qid="data[indextr].url_qr"
                    ></vue-qr>
                  </div>
                </vs-td>

                <vs-td :data="data[indextr].id">
                  <div class="d-flex">
                    <vs-button icon="print" :disabled="loading" @click="print()" class="mr-2" color="success" type="gradient">Imprimir</vs-button>
                    <vs-button icon="create" :disabled="loading" @click="editGraffiti(data[indextr])" class="mr-2" color="primary" type="gradient">Ver</vs-button>
                    <vs-button icon="delete_sweep" :disabled="loading" @click="confirmDelete(data[indextr].id)" color="danger" type="gradient">Eliminar</vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination class="mt-2" :total="total_pages" v-model="current_page"></vs-pagination>
          </div>
        </vs-card>
      </div>
    </div>

    <vs-popup classContent="popup-example" :title="edit ? 'Editar graffiti' : 'Registrar graffiti'" :active.sync="open">
      <div>
        <span v-show="errors.has('name')" class="w-100 text-danger mt-2">
          {{ errors.first("name") }}
        </span>
        <label>Nombre del graffiti *</label>
        <vs-textarea data-vv-as="Nombre del graffiti" name="name" v-validate="'required'" v-model="graffiti.name" />
      </div>

      <div class="mb-2">
        <span v-show="errors.has('description')" class="w-100 text-danger mt-2">
          {{ errors.first("description") }}
        </span><br>
        <label for="">Descripción o reseña del graffiti *</label>
        <vue-editor :editorToolbar="customToolbar" data-vv-as="Descripción del graffiti" name="description" v-validate="'required'"  v-model="graffiti.description" />
      </div>
      <br>
      <div>
        <div class="mb-5">
          <label>Autor del graffiti</label>
          <vs-input v-model="graffiti.author" class="inputx w-100"/>
        </div>
        <div>
          <google-places-autocomplete
            @resultChanged="placeDetail => saveCoords(placeDetail)"
            @resultCleared="() => graffiti.ubication = ''">
            <div slot="input" slot-scope="{ context, events, actions }">
              <label for="locationInput">Ubicación del graffiti</label>
              <vs-input
                v-model="context.input"
                @focus="events.inputHasReceivedFocus"
                @input="events.inputHasChanged"
                @keydown.enter.prevent="actions.selectItemFromList"
                @keydown.down.prevent="actions.shiftResultsSelection"
                @keydown.up.prevent="actions.unshiftResultsSelection"
                type="search"
                id="locationInput"
                placeholder="Escribe la ubicación"
                class="inputx w-100"
              />
            </div>
          </google-places-autocomplete>
          {{graffiti.ubication}}
        </div>
      </div>

      <div class="row mt-5">
        <div class="col d-flex justify-content-between">
          <div>
            <p>Imagen del graffiti <span v-if="!edit">*</span></p>
            <div class="button-wrapper">
              <span class="label">
                {{file_name}}
              </span>
              <input data-vv-as="Imagen del graffiti" name="image" v-validate="!edit ? 'required' : ''" @change="handleFile" type="file" id="upload" class="upload-box" placeholder="Subir archivo">
            </div>
            <span v-show="errors.has('image')" class="w-100 text-danger mt-2">
              {{ errors.first("image") }}
            </span>
          </div>
          <div>
            <vs-button icon="save" v-if="edit" class="mt-5" @click="updateGraffiti" color="primary" type="gradient">Editar</vs-button>
            <vs-button icon="save" v-else class="mt-5" @click="storeGraffiti" color="primary" type="gradient">Guardar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

  </div>
</template>

<script>
import VueQr from 'vue-qr'
import GraffitiService from '@/services/graffitis.service'
import { VueEditor } from "vue2-editor";
import { Printd } from 'printd'

export default {
  components: { VueQr, VueEditor },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }],
        ["clean"]
      ],
      loading: false,
      graffitis: [],
      open: false,
      file_name: 'Seleccionar imagen',
      edit: false,
      graffiti: {
        name: '',
        description: '',
        image: '',
        author: '',
        ubication: '',
        lat: '',
        lng: ''
      },
      current_page: 1,
      total_pages: 1
    }
  },
  watch: {
    open(newVal) {
      if (!newVal) {
        this.edit = false
        this.graffiti = JSON.parse(JSON.stringify(this.$options.data().graffiti))
      }
    }
  },
  methods: {
    print() {
      const cssText = `
        img {
          width: 300px
        }
      `
      const d = new Printd()
      d.print(document.getElementById('print'), [ cssText ])
    },
    saveCoords(value) {
      this.graffiti.lat = value.geometry.location.lat()
      this.graffiti.lng = value.geometry.location.lng()
      this.graffiti.ubication = value.formatted_address
    },
    async storeGraffiti() {
      this.$validator.validateAll().then(async result => {
        try {
          this.loading = true
          const formData = new FormData();
          formData.append('name', this.graffiti.name)
          formData.append('image', this.graffiti.image)
          formData.append('description', this.graffiti.description)
          formData.append('author', this.graffiti.author)
          formData.append('ubication', this.graffiti.ubication)
          formData.append('lat', this.graffiti.lat)
          formData.append('lng', this.graffiti.lng)

          const { data:res } = await GraffitiService.storeGraffiti(formData)

          if (res.success) {
            this.open = false
            this.graffitis.push(res.data)
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }

        } catch(e) {
          this.$swal('Estamos teniendo problemas', '', 'warning')
        }
        this.loading = false
      })
    },
    async updateGraffiti() {
      this.$validator.validateAll().then(async result => {
        try {
          this.loading = true
          const formData = new FormData();
          formData.append('id', this.graffiti.id)
          formData.append('name', this.graffiti.name)
          formData.append('image', this.graffiti.image)
          formData.append('description', this.graffiti.description)
          formData.append('author', this.graffiti.author)
          formData.append('ubication', this.graffiti.ubication)
          formData.append('_method', 'PUT')

          const { data:res } = await GraffitiService.updateGraffiti(formData)

          if (res.success) {
            this.open = false
            const index = this.graffitis.findIndex(i => i.id == res.data.id)
            this.graffitis[index] = res.data
            this.open = false
            this.graffitis = JSON.parse(JSON.stringify(this.graffitis))
            this.$vs.notify({
              title: res.message,
              text:'',
              color: 'success',
              position: 'top-right'
            })
          }

        } catch(e) {
          this.$swal('Estamos teniendo problemas', '', 'warning')
        }
        this.loading = false
      })
    },
    confirmDelete(id) {
      this.$confirm(
        {
          message: `Seguro de eliminar el graffiti?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: async confirm => {
            if (confirm) {
              const { data:res } = await GraffitiService.deleteGraffiti({
                id: id
              })
              if (res.success) {
                const index = this.graffitis.findIndex(i => i.id == res.data.id)
                this.graffitis.splice(index, 1)
              } else {
                this.$swal(res.message, '', 'warning')
              }
            }
          }
        }
      )
    },
    async getGraffitis() {
      this.$vs.loading({
        type: 'point'
      })
      const {data:res} = await GraffitiService.getGraffitis(this.current_page)
      this.graffitis = res.data.data
      this.total_pages = res.data.last_page
      this.$vs.loading.close()
    },
    editGraffiti(item) {
      this.graffiti = JSON.parse(JSON.stringify(item))
      this.open = true
      this.edit = true
    },
    handleFile(event) {
      this.file_name = event.target.files[0].name
      this.graffiti.image = event.target.files[0]
    }
  },
  created() {
    this.getGraffitis()
    //const loading = this.$vs.loading({type: 'points'})
  }
}
</script>
